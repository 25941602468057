.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(5,102,190);
    background: linear-gradient(27deg, rgba(5,102,190,1) 0%, rgba(0,44,84,1) 50%, rgba(0,90,172,1) 100%);

    &__container{
        width: 400px;
        height: 300px;
        padding: 20px;
        
        @include for-big-phone{
            width: 270px;
        }
    }

    

}