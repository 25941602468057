html {
  height: 100%;
}
body {
  min-height: 100%;
}

.seller-dashboard {
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(3, max-content);
}

.icons-container {
  .icon {
    cursor: pointer;
    margin: 0px 3px;
  }

  .edit-icon {
    color: #515151a8;
  }

  .done-icon {
    color: green;
  }

  .cancelled-icon {
    color: red;
  }

  .people-icon {
    color: #002c54;
  }

  .paper-icon {
    color: #27847b;
  }

  .whatsapp-icon {
    color: #128c7e;
  }
}

.patient-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-small-tablet {
    display: inline-block;
  }
}
.dashboard-item-redirect {
  background-color: #49a3f1;
  width: 12rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  transition: 0.2s ease-in-out all;
  box-shadow: none;
  &:hover {
    background-color: #317dc0;
    // width: 15rem;
    // height: 8rem;
    transform: scale(1.05);
    cursor: pointer;
  }
}
