.plan-table-container{
    margin-top: 30px;

    table{
        thead{
            background-color: #053c6e;
            color: white;
            tr{
                th{
                    padding: 10px;
                    p{
                        margin: 0;
                        font-weight: 400;
                    }
                }
                th:not(:first-child){
                    text-align: center;
                }
            }
        }

        tbody{
            tr:not(:last-child){
                border-bottom: 2px solid #b7b1b142;
            }
            tr{
                td{
                    height: 50px;
                    .price{
                        font-family: 'Raleway', sans-serif;
                        font-size: 20px;
                        span{
                            font-size: 12px;
                        }
                    }
                    p{
                        margin: 0;
                    }
                    svg{
                        color:rgb(221, 74, 74);
                    }

                    button{
                        border-radius: 20px;
                        text-transform: capitalize;
                        background-color: #DA2037;
                        margin: 0 10px;

                        &:hover{
                            background-color: #ff5555;
                        }
                    }
                }

                td:not(:first-child){
                    text-align: center;
                    color: #002c54;
                    font-weight: 800;
                }
            }
        }
    }
}

.personal-info-form{
    margin: 35px 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    min-height: 200px;
}

.btn-form-wrapper{
    display: flex;
    justify-content: end;

    .btn{
        border-radius: 20px;
        text-transform: capitalize;
        margin: 0 10px;
    }

    .red-btn{
        background-color: #DA2037;
        &:hover{
            background-color: #ff5555;
        }
    }
}

.review-data{
    margin: 35px 0;
    color:#002c54;
    h3{
        font-weight: 400;
    }

    hr{
        margin-top: 0;
        height: 1px;
        width: 45%;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(218,32,55,1) 50%, rgba(255,255,255,0) 100%);
    }

    .data-container{
        display: flex;
        align-items: center;
        gap: 10px;

        .icon-container{
            text-align: center;
            margin: 20px;
            color: #DA2037;
            cursor: pointer;
        }
    }
}