.users-table-container{
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 50px;
    padding-bottom: 1rem;
}
.gigared-payroll-container {
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 50px;
    padding-bottom: 1rem;
}