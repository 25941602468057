.seller-dashboard{
    @include for-big-tablet{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .second-item{
            order:3
        }
    }

    @include for-small-tablet{
        flex-direction: column;
    }
}
   
.my-purse-container{
    @include for-big-tablet{
        width: 333px;
    }
}

.monthly-resume{

    .table-container{
        border: 2px solid rgba(128, 128, 128, 0.57);
        border-radius: 10px;

        table{
            min-width: 300px;
            color: rgb(0, 44, 84);

            thead{
                tr{
                    border-bottom: 2px solid rgba(128, 128, 128, 0.57);
                    text-align: center;

                    th{
                        p{
                            margin: 2px;
                        }
                    }
                    th:not(:last-child){
                        border-right: 2px solid rgba(128, 128, 128, 0.57);
                    }
                }
            }

            tbody{
                text-align: center;
                tr{
                    td:not(:last-child){
                        border-right: 2px solid rgba(128, 128, 128, 0.57);
                    }
                    td{
                        p{
                            margin: 2px;
                        }
                    }
                }
            }
        }
    }

    .red-text{
        color: rgb(221, 74, 74);
        font-weight: 600;
    }

    .blue-text{
        color: #002c54;
        font-weight: 600;
        margin: 0 !important;
    }

    .liquidations-container{
        margin-top: 20px;
        text-align: center;

        .liquidation-item{
            display: flex;
            align-items: center;
            margin-top: 10px;
            min-width: 300px;
            justify-content: space-between;

            .liquidation-title{
                display: flex;
                align-items: center;
                gap: 10px;
                p{
                    margin:0 !important;
                }
            }
            
        }
    }
}