.cards-container{
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(4, 260px);

    @include for-big-tablet {
        grid-template-columns: repeat(2, 260px);
        column-gap: 155px;
    }
    
    @include for-small-tablet{
        column-gap: 30px;
    }

    @include for-big-phone{
        grid-template-columns: repeat(1, 260px);
    }
}

.card-container{
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-container{
        width: 60px;
        height: 60px;
        text-align: center;
        border-radius: 15px;
        position: relative;
        z-index: 1000;
        left: 24px;
    }

    .card{
        height: 118px;
        width: 252px;
        display: flex;
        align-items: flex-end;
        justify-content: end;
        text-align: end;
        position: relative;
        bottom: 40px;

        .title{
            font-size: 16px;
            font-weight: 100;
        }
    }
}

.seller-card{
    width: 340px;
    border-radius: 2ps;
    background-color: white;
    margin-top: 10px;

    @include for-small-tablet{
        width: auto;
    }
}

.title-card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .icon{
        color: #cf1c1c;
    }

    h4{
        margin: 0;
        color: #002c54;
        font-weight: 400;
    }
}