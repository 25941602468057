
.layout{
  display: flex;
  background-color:#e8f0ef;
  height: max-content;
  
  @include for-small-phone {
    display: inline-flex;
  }

  .sidebar{
    height: max-content;
    margin: 15px;

    div{
      background-color: #002c54;
      color: #ffffff;
      font-weight: 400;
      border-radius: 10px;
    }
    hr {
      margin: 25px 0;
      height: 1px;
      border: 0;
      background: linear-gradient(63deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,248,248,0) 100%);
    }
  }

  .sidebar-is-open{
    display: inline-block !important;

    @include for-laptop {
      z-index: 9999999;
      position: absolute;
    }
  }

  .sidebar-is-closed{
    display: none !important;
  }

  .container{
    flex-grow: 1;
    margin-top: 15px;
    min-height: 110vh;

    @include for-big-tablet {
      margin: 0;
      max-width: 100% !important;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 80%;
  }
}


.dashboard-chart{
  display: flex;
  justify-content: space-around;

  @include for-big-tablet {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
}

.center-flex{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.center-img {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.report{
  height: 90vh;
  width: 101%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__container{
      width: 400px;
      height: 300px;
      padding: 20px;

      @include for-big-phone{
          width: 270px;
      }
  }
}